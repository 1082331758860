import React, {useState} from 'react';
import { observer } from 'mobx-react'
import './index.css';
import GlobalState, {changepopupworkspace, addactivedataset, addsegmentations, changepopupdataset, changepopupdeleteWorkspace} from "../../stores/GlobalState";
import Domen from "../../stores/Domen";
import Pool from "../autorize/UserPool";
import lang from "../../lang"

import Loading from '../common/loading';
import LoadingCompleted from '../common/loading_completed';
import LoadingFail from '../common/loading_fail';
const PopupInfo = observer(() =>{
    
    const [load, setLoad] = useState(0);
    const [wname, setWname] = useState(GlobalState.activedatasetSegment.nameSegment);
    const [datasetLine, setDatasetLine] = useState(GlobalState.activedatasetSegment.file_key);
    const [datasetColumns, setDatasetColumns] = useState("");
    const [datasetRows, setDatasetRows] = useState("");
    const [datasetdatasetname, setDatasetdatasetname] = useState("");
    const [datasetfilekey, setDatasetfilekey] = useState("");
    const [datasetfilename, setDatasetfilename] = useState("");
    const [datasetkeycolumn, setDatasetkeycolumn] = useState("");
    const [datasetsource, setDatasetsource] = useState("");
    const [datasetstatus, setDatasetstatus] = useState("");
    const [datasetuploadtime, setDatasetuploadtime] = useState("");
    const [datasetuserid, setDatasetuserid] = useState("");
    const [langmenu, setLangmenu] = useState(localStorage.getItem("lang_site")||"en")
    const [langv, setLangv]= useState(langmenu=="de"?lang.de:lang.en)

    const [textloading, setTextloading] = useState("Workspace Creation In progress");
    const [textloadingcompleated, setTextloadingcompleated] = useState("Workspace  Created");
    const [textloadingUpdate, setTextloadingUpdate] = useState("Workspace  Update");


    const handleRadioChange = (id, 
        Columns, 
        Rows, 
        dataset_name,
        file_key,
        file_name,
        key_column,
        source,
        status,
        upload_time,
        user_id) => {
        if(GlobalState.activedatasetSegment.nameSegment=="")
        { 
            setDatasetLine(id); 
            setDatasetColumns(Columns);
            setDatasetRows(Rows);
            setDatasetdatasetname(dataset_name);
            setDatasetfilekey(file_key);
            setDatasetfilename(file_name);
            setDatasetkeycolumn(key_column);
            setDatasetsource(source);
            setDatasetstatus(status);
            setDatasetuploadtime(upload_time);
            setDatasetuserid(user_id);
        }
    }; 

    const updateGeneralSettings0 = async (n, name, workspace_key) => {

        var token = localStorage.getItem("token");
        const response = await fetch(Domen.url + `segmentations/?key_file=`+n, {
            method: 'GET',
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json',
            }
        })
        
        if(response.status=="200")
        {
            const data = await response.json();
            console.log(data)

            if( data.segmentations!="")
            {
                var dataset = {
                    id: data._id,
                    idWorkspace: workspace_key,
                    nameSegment: name,
                    nameDataset: data.name,
                    Columns: "", 
                    Rows: "", 
                    file_key: data.key_file,
                    file_name: "",
                    segmentations: data.segmentations,
                    key_column: "",
                    source: "",
                    status: "",
                    upload_time: n.upload_time,
                    user_id: data.user_id
                };
                addactivedataset(dataset);

                var segmentations = {
                    date_create: data.date_create,
                    key_file: data.key_file,
                    name: data.name,
                    segmentations: data.segmentations,
                    user_id: data.user_id,
                    _id: data._id,
                }
                addsegmentations(segmentations);
            }
            else
            {
                alert(data.status)
            }
            
        } 
    }

    const updateGeneralSettings = async () => {
        setLoad(1);
        if(GlobalState.activedatasetSegment.nameSegment=="")
        { 
            var token = localStorage.getItem("token");
            var workspace = {
                name: wname,
                dataset_key: datasetfilekey,
                status: "Active"
            };
            const response = await fetch(Domen.url + `segmentations/workspace`, {
                method: 'POST',
                headers: {
                    'Authorization': token,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(workspace),
            })
            if(response.status=="200")
            {
                const data = await response.json();
               setLoad(3)
                 
                
                setTimeout(function(){
                    updateGeneralSettings0(data?.workspace?.dataset_key, data?.workspace?.name,data?.workspace?._id)
                
                    changepopupworkspace(); 
                    setLoad(0)
                }, 2000)
            }
            else
            {
                const user = Pool.getCurrentUser();
                user.getSession((err, session) => {
                    if (err) {
                        console.error("Error fetching user session:", err);                       
                    } 
                    else 
                    {
                        if (!session) {
                            console.log("No session found");
                            return;
                        }
                        else
                        {
                            const refreshToken  = session.getRefreshToken();
                            user.refreshSession(refreshToken, (err, result) => {
                                const { accessToken } = result;
                                localStorage.setItem("token", accessToken.jwtToken);
                                updateGeneralSettings();
                            });
                        }
                        
                    }
                })
                setLoad(2)
                setTimeout(function(){
                    setLoad(0)
                }, 2000)
            }
        }
        else
        {
            var token = localStorage.getItem("token");
            var workspace = {
                new_name: wname,
            };
            const response = await fetch(Domen.url + `segmentations/workspace/`+ GlobalState.activedatasetSegment.idWorkspace, {
                method: 'PATCH',
                headers: {
                    'Authorization': token,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(workspace),
            })
            if(response.status=="200")
            {
                setLoad(4)
                const data = await response.json();
                
                setTimeout(function(){
                    updateGeneralSettings0(GlobalState.activedatasetSegment.file_key, wname, GlobalState.activedatasetSegment.idWorkspace)
                    changepopupworkspace();  
                    setLoad(0)
                }, 2000)
            }
        }
    };
    const deleteGeneralSettings = async () => {
        changepopupworkspace()
        changepopupdeleteWorkspace()
    };
    
    const changepopupdatasetopen = () =>{
        changepopupworkspace()
        changepopupdataset()
    }
    
    return (
        <div className="popupInfofon">
            <div className="popupInfo addwork adw ">
                
                {
                                
                    load==1?
                    <Loading text = {textloading}/>
                    :
                    <></>
                }
                {
                    load==2?
                    <LoadingFail text = {textloadingcompleated}/>
                    :
                    <></>
                }
                {
                    load==3?
                    <LoadingCompleted text = {textloadingcompleated}/>
                    :
                    <></>
                    
                }
                {
                    load==4?
                    <LoadingCompleted text = {textloadingUpdate}/>
                    :
                    <></>
                    
                }
                {
                    load==0?
                    <>
                        <div className='top-element'>
                            <div className='namepopup'>
                                {langv?.segmentation?.work_creation?.text1}
                            </div>
                            {/* <div className='separator'></div> */}
                            <div></div>
                            <div className='buttonspopup'>
                                <div className={GlobalState.activedatasetSegment.nameSegment===''?'buttonsave':'buttonsave active'} onClick={deleteGeneralSettings}>{langv?.segmentation?.work_creation?.text2}</div>
                                <div className={wname===''?'buttonsave':'buttonsave active'} onClick={updateGeneralSettings}>{langv?.segmentation?.work_creation?.text3}</div>
                                <div className='buttonexit' onClick={changepopupworkspace}>
                                    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M18.7891 5.27441L6.24236 18.181" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
                                        <path d="M6.06055 5.45312L18.9671 17.9998" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div className='form-element'>
                            <div className='linenamework'>
                                <input
                                    id="workspacename"
                                    type="text"
                                    placeholder={langv?.segmentation?.work_creation?.text4}
                                    className="pop-input"
                                    value={wname}
                                    onChange={(event) => setWname(event.target.value)}
                                />
                            </div>
                        </div>
                        <div className='datasetblock'>
                            <div className='title_datasetblock'>
                            {langv?.segmentation?.work_creation?.text5}
                            </div>
                            <div className='block_dataset_lines'>
                                <div className='datasethead'>
                                <div className='topfield field0 ff0'></div>
                                <div className='topfield field1'>{langv?.segmentation?.work_creation?.text6}</div>
                                <div className='topfield field2'>{langv?.segmentation?.work_creation?.text7}</div>
                                <div className='topfield field3'>{langv?.segmentation?.work_creation?.text8}</div>
                                <div className='topfield field5'>{langv?.segmentation?.work_creation?.text10}</div>
                                <div className='topfield field4'>{langv?.segmentation?.work_creation?.text9}</div>
                                <div className='topfield field6'>{langv?.segmentation?.work_creation?.text11}</div>

                            </div>
                            {
                                
                                GlobalState.dataset?.map((n, id)=>
                                    n.source=="csv"?
                                    <div className={datasetLine === n.id?'datasetbody active':'datasetbody'}>
                                
                                        <div className='topfield field0 ff0' onClick={()=>handleRadioChange(n.id, n.columns, n.rows, n.dataset_name, n.file_key, n.file_name, n.key_column, n.source, n.status, n.upload_time,n.user_id)} >

                                            {datasetLine === n.id?
                                                <svg className='activedatasetsegment' width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M13.5 9.99992C13.5 12.2091 11.7091 13.9999 9.5 13.9999C7.29086 13.9999 5.5 12.2091 5.5 9.99992C5.5 7.79079 7.29086 5.99993 9.5 5.99993C11.7091 5.99993 13.5 7.79079 13.5 9.99992Z" fill="#33A753"/>
                                                    <path d="M13.5 9.99992C13.5 12.2091 11.7091 13.9999 9.5 13.9999C7.29086 13.9999 5.5 12.2091 5.5 9.99992C5.5 7.79079 7.29086 5.99993 9.5 5.99993C11.7091 5.99993 13.5 7.79079 13.5 9.99992Z" stroke="#33A753" stroke-width="1.5"/>
                                                    <path d="M8.5 1.57728C9.1188 1.22001 9.8812 1.22001 10.5 1.57728L16.2942 4.92257C16.913 5.27984 17.2942 5.94009 17.2942 6.65463V13.3452C17.2942 14.0598 16.913 14.72 16.2942 15.0773L10.5 18.4226C9.8812 18.7798 9.1188 18.7798 8.5 18.4226L2.70577 15.0773C2.08697 14.72 1.70577 14.0598 1.70577 13.3452L1.70577 6.65462C1.70577 5.94009 2.08697 5.27984 2.70577 4.92257L8.5 1.57728Z" stroke="#33A753" stroke-width="1.5"/>
                                                </svg>
                                                :
                                                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8.5 1.07735C9.1188 0.720085 9.8812 0.720085 10.5 1.07735L16.2942 4.42265C16.913 4.77992 17.2942 5.44017 17.2942 6.1547L17.2942 12.8453C17.2942 13.5598 16.913 14.2201 16.2942 14.5774L10.5 17.9226C9.8812 18.2799 9.1188 18.2799 8.5 17.9226L2.70577 14.5774C2.08697 14.2201 1.70577 13.5598 1.70577 12.8453L1.70577 6.1547C1.70577 5.44017 2.08697 4.77992 2.70577 4.42265L8.5 1.07735Z" stroke="white" stroke-width="1.5"/>
                                                </svg>
                                            }
                                        </div>
                                        <div className='topfield field1'>
                                            <input
                                                id="dataset"
                                                type="text"
                                                disabled="true"
                                                placeholder=""
                                                className="pop-input"
                                                value={n.name}
                                                //onChange={(event) => setDataset(event.target.value)}
                                            />
                                        </div>
                                        <div className='topfield field2'>
                                            <input
                                                id="source"
                                                type="text"
                                                disabled="true"
                                                placeholder=""
                                                className="pop-input"
                                                value={n.source}
                                                //onChange={(event) => setSource(event.target.value)}
                                            />
                                        </div>
                                        <div className='topfield field3'>
                                            <input
                                                id="lastchanges"
                                                type="text"
                                                disabled="true"
                                                placeholder=""
                                                className="pop-input"
                                                value={n.last_changes}
                                                //onChange={(event) => setLastchanges(event.target.value)}
                                            />
                                        </div>
                                        <div className='topfield field5'>
                                            <input
                                                id="columns"
                                                type="text"
                                                disabled="true"
                                                placeholder=""
                                                className="pop-input"
                                                value={n.columns}
                                                //onChange={(event) => setColumns(event.target.value)}
                                            />
                                        </div>
                                        <div className='topfield field4'>
                                            <input
                                                id="rows"
                                                type="text"
                                                disabled="true"
                                                placeholder=""
                                                className="pop-input"
                                                value={n.rows}
                                                //onChange={(event) => setRows(event.target.value)}
                                            />
                                        </div>
                                        <div className='topfield field6'>
                                            <input
                                                id="objectid"
                                                type="text"
                                                disabled="true"
                                                placeholder=""
                                                className="pop-input"
                                                value={n.object_ID}
                                                //onChange={(event) => setObjectid(event.target.value)}
                                            />
                                        </div>
                                        
                                    </div>
                                    :
                                    <></>
                                )
                            }
                            </div>
                            <div className='upload_dataset' onClick={()=>changepopupdatasetopen()}>
                                <svg className='defalt_upload_dataset' width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M31.9226 15C32.2799 15.6188 32.2799 16.3812 31.9226 17L25.0773 28.8564C24.7201 29.4752 24.0598 29.8564 23.3453 29.8564L9.6547 29.8564C8.94017 29.8564 8.27991 29.4752 7.92265 28.8564L1.07735 17C0.720084 16.3812 0.720084 15.6188 1.07735 15L7.92265 3.14359C8.27991 2.52479 8.94017 2.14359 9.6547 2.14359L23.3453 2.14359C24.0598 2.14359 24.7201 2.52479 25.0774 3.14359L31.9226 15Z" fill="#2D3239" stroke="#2D3239"/>
                                    <path d="M16.5326 9.99992L14.3533 12.1798C14.0383 12.4948 14.2614 13.0333 14.7069 13.0333H16.104V19.0659H16.9611V13.0333H18.3583C18.8037 13.0333 19.0268 12.4948 18.7119 12.1798L16.5326 9.99992Z" fill="white"/>
                                    <path d="M10.5 20.9998V21.8569H11.3571H16.5H21.6429H22.5V20.9998H10.5Z" fill="white"/>
                                </svg>
                                <svg className='hover_upload_dataset' width="34" height="32" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M32.4226 15C32.7799 15.6188 32.7799 16.3812 32.4226 17L25.5773 28.8564C25.2201 29.4752 24.5598 29.8564 23.8453 29.8564L10.1547 29.8564C9.44017 29.8564 8.77991 29.4752 8.42265 28.8564L1.57735 17C1.22008 16.3812 1.22008 15.6188 1.57735 15L8.42265 3.14359C8.77991 2.52479 9.44017 2.14359 10.1547 2.14359L23.8453 2.14359C24.5598 2.14359 25.2201 2.52479 25.5774 3.14359L32.4226 15Z" fill="#F5C538" stroke="#F5C538"/>
                                    <path d="M17.0326 10.0004L14.8533 12.1803C14.5383 12.4953 14.7614 13.0338 15.2069 13.0338H16.604V19.0664H17.4611V13.0338H18.8583C19.3037 13.0338 19.5268 12.4953 19.2119 12.1803L17.0326 10.0004Z" fill="#020203"/>
                                    <path d="M17.0326 10.0004L14.8533 12.1803C14.5383 12.4953 14.7614 13.0338 15.2069 13.0338H16.604V19.0664H17.4611V13.0338H18.8583C19.3037 13.0338 19.5268 12.4953 19.2119 12.1803L17.0326 10.0004Z" fill="black" fill-opacity="0.2"/>
                                    <path d="M17.0326 10.0004L14.8533 12.1803C14.5383 12.4953 14.7614 13.0338 15.2069 13.0338H16.604V19.0664H17.4611V13.0338H18.8583C19.3037 13.0338 19.5268 12.4953 19.2119 12.1803L17.0326 10.0004Z" fill="black" fill-opacity="0.2"/>
                                    <path d="M11 21.0003V21.8574H11.8571H17H22.1429H23V21.0003H11Z" fill="#020203"/>
                                    <path d="M11 21.0003V21.8574H11.8571H17H22.1429H23V21.0003H11Z" fill="black" fill-opacity="0.2"/>
                                    <path d="M11 21.0003V21.8574H11.8571H17H22.1429H23V21.0003H11Z" fill="black" fill-opacity="0.2"/>
                                </svg>

                                &nbsp;&nbsp;{langv?.segmentation?.work_creation?.text12}
                            </div>
                        </div>
                    </>
                    :
                    <></>
                }
            </div>
        </div>
    );
});

export default PopupInfo;
