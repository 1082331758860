import React, {useEffect, useState} from 'react';
import { observer } from 'mobx-react'
import Select, { components } from 'react-select'
import './index.css';
import GlobalState, { changepopupMicrosegment, addmickrosegments, changemikrosegmentations} from "../../stores/GlobalState";
import lang from "../../lang"
import Domen from "../../stores/Domen";
const PopupMicrosegment = observer(() =>{

    const [save, setSave] = useState(0);
    const [langmenu, setLangmenu] = useState(localStorage.getItem("lang_site")||"en")
    const [langv, setLangv]= useState(langmenu=="de"?lang.de:lang.en)
    const [selectall, setSelectAll] = useState("");
    const [mikro, setMikro] = useState([{
        title: "Revenue",
        options: [{
          name: "Low",
          subname: "Number of Objects",
          value: "7 000",
          cheked: "false"
        },
        {
          name: "Mid Low",
          subname: "Number of Objects",
          value: "8 500",
          cheked: "false"
        },
        {
          name: "Mid",
          subname: "Number of Objects",
          value: "2 400",
          cheked: "false"
        },
        {
          name: "Mid High",
          subname: "Number of Objects",
          value: "12 857",
          cheked: "true"
        }]
      }])
    const [flag, setFlag] = useState(0);
    const saveMicro = async() =>{
        setFlag(0);
        var potok = selectall.split('; ');
        var req = new Object();
        for(var i = 0; i < potok.length; i++)
        {
            var key = potok[i].split(':')[0];
            var par = potok[i].split(':')[1];
            var red = par?.split(',');
            var mass = [];
            for(var s = 0; s < red?.length; s++)
            {
               
                if(red[s]!=" ")
                {
                    mass.push(red[s]?.replace(" ", ""))
                }
            }
            if(key!='')
            {
                req[key] = mass;
            }
            
        }
        // req["Country"] = ["United Kingdom"];
        var token = localStorage.getItem("token");
        var product = {
            filters: req
        };
        console.log(product)
        const response = await fetch(Domen.url + `segmentations/update_micro_segment/?campaign_key=`+GlobalState.mickrosegments?.key_campaign, {
            method: 'POST',
            headers: {
                'Authorization': token,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(product),
        })
        if(response.status>199&&response.status<399)
        {
            const data = await response.json();
            changepopupMicrosegment();
           
        }  
    }

    let options = [];
    let select = "";
    console.log(GlobalState.mickrosegments?.micro_segmentations?.filtered_columns_segments)

    
    useEffect(() => {
        select = "";

        Object.entries(GlobalState.mickrosegments?.micro_segmentations?.filtered_columns_segments||{}).map(([key, value])=>
            {
                select = select + key + ": ";
                value.map((n, i)=>
                    select = select + n + ', '
                )
                select = select.slice(0, -2);
                select = select + '; '
            }
        )
        //setSelectAll(select)
        if(selectall=='')
        {
            setSelectAll(select)
        }
        
    }, []);


    Object.entries(GlobalState.mickrosegments?.micro_segmentations?.micro_segment?.cat||{}).map(([key, value])=>
        options = [...options, {value: key, label: key}]
    )
    Object.entries(GlobalState.mickrosegments?.micro_segmentations?.micro_segment?.num||{}).map(([key, value])=>
        options = [...options, {value: key, label: key}]
    )

   
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            background: 'rgba(45, 50, 57, 1)',
            display: 'flex',
            flexWrap: 'nowrap',
            width: '314px',
            height: '58px',
            color: 'rgba(245, 197, 56, 1)',
            border: '0 !important',
            boxShadow: 'none',
            '&:hover': {
            border: '0 !important',
            },
            '&:focus': {
                border: '0 !important',
            }
        }),
        
        menu: (provided) => ({
            ...provided,
            background: 'rgba(45, 50, 57, 1)',
            width: '314px',
            marginTop: '-7px',
            boxShadow: 'none',
            cursor: 'pointer'
        }),
        option: (provided, state) => ({
            ...provided,
            fontSize: 15,
            color: state.isSelected ? 'rgba(235, 115, 27, 1)' : 'white',
            backgroundColor: 'rgba(255, 255, 255, 0)', 
            '&:hover': {
                color: 'rgba(235, 115, 27, 1)',
            },
            '&:focus': {
                backgroundColor: 'rgba(255, 255, 255, 0)', 
            },
            '&:active': {
                backgroundColor: 'rgba(255, 255, 255, 0)', 
            }
            }),
    };
    const customStylesSegment = {
        control: (provided, state) => ({
            ...provided,
            background: 'rgba(45, 50, 57, 1)',
            display: 'flex',
            flexWrap: 'nowrap',
            width: '172px',
            height: '38px',
            color: 'rgba(245, 197, 56, 1)',
            border: '0 !important',
            borderRadius: "8px !important",
            boxShadow: 'none',
            '&:hover': {
            border: '0 !important',
            },
            '&:focus': {
                border: '0 !important',
            }
        }),
        
        menu: (provided) => ({
            ...provided,
            background: 'rgba(45, 50, 57, 1)',
            width: '172px',
            marginTop: '-7px',
            boxShadow: 'none',
            cursor: 'pointer'
        }),
        option: (provided, state) => ({
            ...provided,
            fontSize: 15,
            color: state.isSelected ? 'rgba(235, 115, 27, 1)' : 'white',
            backgroundColor: 'rgba(255, 255, 255, 0)', 
            '&:hover': {
                color: 'rgba(235, 115, 27, 1)',
            },
            '&:focus': {
                backgroundColor: 'rgba(255, 255, 255, 0)', 
            },
            '&:active': {
                backgroundColor: 'rgba(255, 255, 255, 0)', 
            }
            }),
    };
    const DropdownIndicator = props => {
        return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                {
                props.selectProps.menuIsOpen ? 
                    <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="9" cy="12" r="2" fill="white"/>
                        <path d="M1 9L9.24242 1L17 9" stroke="white"/>
                    </svg>
                    : 
                    <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="2" cy="2" r="2" transform="matrix(1 0 0 -1 7 4.5)" fill="white"/>
                        <path d="M1 5.5L9.24242 13.5L17 5.5" stroke="white"/>
                    </svg> 
                }
            </components.DropdownIndicator>
        )
        );
    };

    const AddMickro = async() =>{
        var prod = GlobalState.mikrosegmentations;
        prod = [...prod, {name: "select", parametrs: [], check: "true"}]
        changemikrosegmentations(prod);
    }
    
    const changecategorymickro = (a, b) =>{
        var prod = GlobalState.mikrosegmentations;
        var params = [];
        Object.entries(GlobalState.mickrosegments?.micro_segmentations?.micro_segment?.cat||{}).map(([n, val])=>                           
            n==a?
            params = val
            :
            <></>
        )
        Object.entries(GlobalState.mickrosegments?.micro_segmentations?.micro_segment?.num||{}).map(([n, val])=>                           
            n==a?
            params = val
            :
            <></>
        )
        for(var i = 0; i < prod.length; i++)
        {
            if(i==b)
            {
                prod[i].name = a;
                prod[i].parametrs = params;
            }
        }
        changemikrosegmentations(prod);
    }

    console.log(GlobalState.mickrosegments)


    const checkparam = (a, b)=>{
        var sel = selectall.split('; ');
        var vuh = "";
        var fl = 0;
        for(var i = 0; i < sel.length; i++)
        {
            var key = sel[i].split(': ')[0];
            var par = sel[i].split(': ')[1];
            
            if(key==b)
            {
                fl = 1;
                console.log(key);
                if(par.includes(a))
                {
                    par = par.replace(a+', ', '');
                    par = par.replace(', '+a, '');
                    par = par.replace(a, '');
                } 
                else
                {
                    if(par!='')
                    {
                        par = par +', '+a;
                    }
                    else
                    {
                        par = par +a;
                    }
                    
                } 
                
            }
            if(key!='')
            {
                vuh = vuh + key+": "+par+'; '
            }
            
        }
        if(fl==0){
            vuh = vuh + b+": "+a+'; '
        }
        setSelectAll(vuh) 
        setFlag(1)
    }
    return (
        <div className="popupInfofon">
            <div className="popupInfo popmicrosegment">
                <div className='top-element'>
                    <div className='namepopup'>
                        {langv?.compaings?.microsegment?.text1}
                    </div>
                    {/* <div className='separator'></div> */}
                    <div></div>
                    <div className='buttonspopup'>
                        <div className={flag===0?'buttonsave':'buttonsave active'} onClick={()=>saveMicro() }>{langv?.compaings?.microsegment?.text2}</div>
                        <div className='buttonexit' onClick={changepopupMicrosegment}>
                            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18.7891 5.27441L6.24236 18.181" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
                                <path d="M6.06055 5.45312L18.9671 17.9998" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
                            </svg>
                        </div>
                    </div>
                </div>
                <div className='form-element'>
                    <div className='line_head_micro'>
                        <div className='tittle_head_micro'>
                        {selectall||langv?.compaings?.microsegment?.text3}
                        </div>
                        <div className='value_head_micro'>
                            <span>
                                {GlobalState.mickrosegments?.micro_segmentations?.total_count}
                            </span>
                            {langv?.compaings?.microsegment?.text4}
                        </div>
                    </div>
                    <div className='line_body_micro'>
                        {
                           // Object.entries(GlobalState.mickrosegments?.micro_segmentations?.filtered_columns_segments||{}).map(([key, value])=>
                            
                            GlobalState.mikrosegmentations.map((r, i)=>
                                r.check=="true"?
                                <div className='selectMickro'>

                                    <Select 
                                        components={{ DropdownIndicator }}
                                        options={options} 
                                        styles={customStyles} 
                                        classNamePrefix='filter'
                                        className='selectproduct'
                                        isSearchable={false} 
                                        placeholder={<div className='selectplace active'>{r.name}</div>}
                                        onChange={(option) => changecategorymickro(option.value, i)}
                                    />
                                    
                                    <div className='mikro_select'>
                                        {
                                            Object.entries(r.parametrs).map(([z, g])=>
                                                z!='Other'&&z!='Missing Values'?
                                                selectall.includes(z)?
                                                <div className='element_select active' onClick={()=>checkparam(z, r.name)}>
                                                    <div className='element_select_left'>
                                                        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <circle cx="9" cy="9.5" r="4" fill="#33A753"/>
                                                            <path d="M8 1.07735C8.6188 0.720085 9.3812 0.720085 10 1.07735L15.7942 4.42265C16.413 4.77992 16.7942 5.44017 16.7942 6.1547L16.7942 12.8453C16.7942 13.5598 16.413 14.2201 15.7942 14.5774L10 17.9226C9.3812 18.2799 8.6188 18.2799 8 17.9226L2.20577 14.5774C1.58697 14.2201 1.20577 13.5598 1.20577 12.8453L1.20577 6.1547C1.20577 5.44017 1.58697 4.77992 2.20577 4.42265L8 1.07735Z" stroke="#33A753" stroke-width="1.5"/>
                                                        </svg>
                                                        <div className='element_select_left_title'>
                                                            {z}
                                                        </div>
                                                    </div>
                                                    <div className='element_select_right'>
                                                        <div className='element_select_right_title'>
                                                            
                                                            {
                                                                Object.entries(g).map(([y, j])=>
                                                                    y=="count"?
                                                                    j:
                                                                    <></>
                                                                )
                                                            }
                                                            {/* {z.value} */}
                                                        </div>
                                                        <div className='element_select_right_subtitle'>
                                                            
                                                            {langv?.compaings?.microsegment?.text6}
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <div className='element_select' onClick={()=>checkparam(z, r.name)}>
                                                    <div className='element_select_left'>
                                                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <g opacity="0.6">
                                                            <path d="M11 4.07735C11.6188 3.72008 12.3812 3.72008 13 4.07735L18.7942 7.42265C19.413 7.77992 19.7942 8.44017 19.7942 9.1547L19.7942 15.8453C19.7942 16.5598 19.413 17.2201 18.7942 17.5774L13 20.9226C12.3812 21.2799 11.6188 21.2799 11 20.9226L5.20577 17.5774C4.58697 17.2201 4.20577 16.5598 4.20577 15.8453L4.20577 9.1547C4.20577 8.44017 4.58697 7.77992 5.20577 7.42265L11 4.07735Z" stroke="white" stroke-width="1.5"/>
                                                            </g>
                                                        </svg>
                                                        <div className='element_select_left_title'>
                                                            {z}
                                                        </div>
                                                    </div>
                                                    <div className='element_select_right'>
                                                        <div className='element_select_right_title'>
                                                            
                                                            {
                                                                Object.entries(g).map(([y, j])=>
                                                                    y=="count"?
                                                                    j:
                                                                    <></>
                                                                )
                                                            }
                                                            {/* {z.value} */}
                                                        </div>
                                                        <div className='element_select_right_subtitle'>
                                                        {langv?.compaings?.microsegment?.text6}
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <></>
                                            )
                                        }
                                        
                                    </div>
                                </div>
                                :
                                <></>
                            )
                        }
                        
                        <div className='but_mickrosegment' onClick={()=>AddMickro()}>
                            <svg width="34" height="32" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M32.4226 15C32.7799 15.6188 32.7799 16.3812 32.4226 17L25.5773 28.8564C25.2201 29.4752 24.5598 29.8564 23.8453 29.8564L10.1547 29.8564C9.44017 29.8564 8.77991 29.4752 8.42265 28.8564L1.57735 17C1.22008 16.3812 1.22008 15.6188 1.57735 15L8.42265 3.14359C8.77991 2.52479 9.44017 2.14359 10.1547 2.14359L23.8453 2.14359C24.5598 2.14359 25.2201 2.52479 25.5774 3.14359L32.4226 15Z" fill="#F5C538" stroke="#F5C538"/>
                                <path d="M17 9V23" stroke="#020203" stroke-linecap="round"/>
                                <path d="M17 9V23" stroke="black" stroke-opacity="0.2" stroke-linecap="round"/>
                                <path d="M17 9V23" stroke="black" stroke-opacity="0.2" stroke-linecap="round"/>
                                <path d="M10 16H24" stroke="#020203" stroke-linecap="round"/>
                                <path d="M10 16H24" stroke="black" stroke-opacity="0.2" stroke-linecap="round"/>
                                <path d="M10 16H24" stroke="black" stroke-opacity="0.2" stroke-linecap="round"/>
                            </svg>
                            <div dangerouslySetInnerHTML={{ __html: langv?.compaings?.microsegment?.text5}}></div>
                           
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default PopupMicrosegment;
